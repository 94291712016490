import { signInRoutePath } from 'config/routePaths'

const API_PATH_PREFIX = '/api/v1/'

const buildEndpoint = url =>
  url.startsWith('http')
    ? url
    : `${API_PATH_PREFIX}${url.replace(/^\//, '')}`

const wantAuthorizationHeader = url => !!Api.token
  && url.startsWith(API_PATH_PREFIX)
  && url !== buildEndpoint(signInRoutePath)

const Api = {
  token: null,

  delete: (url, options) => fetchWithJwt('DELETE', buildEndpoint(url), null, options),
  get: (url, options) => fetchWithJwt('GET', buildEndpoint(url), null, options),
  patch: (url, body, options) => fetchWithJwt('PATCH', buildEndpoint(url), body, options),
  post: (url, body, options) => fetchWithJwt('POST', buildEndpoint(url), body, options),
}

async function fetchWithJwt(method, url, body, options = {}) {
  const headers = options.headers || {}
  headers.Accept = 'application/json'

  if (wantAuthorizationHeader(url)) {
    headers.Authorization = `Bearer ${Api.token}`
  }

  const requestInit = {
    headers,
    method,
  }

  if (body && method !== 'GET') {
    requestInit.headers['Content-Type'] = 'application/json'
    requestInit.body = JSON.stringify(body)
  }

  try {
    const response = await fetch(url, requestInit)
    const json = await response.json()

    if (response.status >= 400) {
      options?.onFailure?.(response, json)
    } else {
      options?.onSuccess?.(response, json)
    }

    if (json.error) {
      throw new Error(json.message || json.error)
    }

    return json
  } catch (error) {
    throw new Error(error.toString().replace(/^(?:Type)?Error: /, ''))
  }
}

export default Api
