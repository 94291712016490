import React, { Component, Fragment } from 'react'

// Examples

import { Modal, ModalHeader, ModalBody, ModalFooter, CardFooter, Container, Row, Card, Col, Form, FormGroup, Table, Input, Button, CardHeader } from 'reactstrap'
import { Link, Switch, Route } from 'react-router-dom'

import * as CopyToClipboard from 'react-copy-to-clipboard'

import { withRouter } from 'react-router-dom'
import { DeploymentsNew } from './new'
import { DeploymentsEditDrive } from './Drives/edit'
import { DeploymentsEdit } from './edit'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHdd, faTrash } from '@fortawesome/free-solid-svg-icons'
import Api from 'utils/api'

var licenseKeyWithDashes = (key, width = 5) => {
  var k = ''
  for (var i = 0; i < key.length; i += width) {
    var slice = key.slice(i, i + width)
    if (i + width < key.length) {
      slice += '-'
    }
    k += slice
  }
  return k
}

class DeleteConfirmModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      name: '',
      password: null,
      deleteDisabled: true,
    }

    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
    })
  }

  handleChange = (e, field) => {
    var newState = {}
    newState[field] = e.target.value
    if (field == 'name') {
      if (e.target.value == this.props.name) {
        this.setState({ deleteDisabled: false })
      } else {
        this.setState({ deleteDisabled: true })
      }
    }
    this.setState(newState)
  }

  handleDeleteAccount(/* id */) {
    var self = this
    var id = this.props.id

    Api.patch(`/configurations/${id}`, { status: 'trashed' })
      .then(() => {
        self.props.loadconfigurations()
      })
  }

  render() {
    var self = this

    return (
      <span className="d-inline-block mb-2 mr-2">

        <a href="#" onClick={this.toggle}><FontAwesomeIcon className="ml-2 opacity-5" icon={faTrash} /></a>
        <Modal isOpen={this.state.modal} toggle={this.toggle} >
          <ModalHeader toggle={this.toggle}>Delete configuration</ModalHeader>
          <ModalBody>
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormGroup>
                If you delete this configuration all installed ExpanDrive clients will become unlicensed and your configuration and licenses cannot be restored.
                <br /><br />
                To delete your account, type &quot;<span style={{ color: 'red' }}>{self.props.name}</span>&quot;, then click the Delete button.<br />

                <Input autoFocus value={this.state.name} onChange={(e) => self.handleChange(e, 'name')} type="text"
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="link" onClick={this.toggle}>Cancel</Button>
            <Button disabled={this.state.deleteDisabled} color="danger" className="btn-pill btn-widee fsize-1" size="md" onClick={this.handleDeleteAccount.bind(this)}>Delete</Button>{' '}
          </ModalFooter>
        </Modal>
      </span>
    )
  }
}

class CopyableSpan extends Component {
  state = { copied: '' }

  render() {
    var self = this
    return (<CopyToClipboard text={this.props.text}
      onMouseOver={() => {
        if (this.state.copied == '')
          self.setState({ copied: 'Copy' })
      }}
      onMouseLeave={() => {
        if (this.state.copied == 'Copy')
          self.setState({ copied: '' })
      }}
      onCopy={() => {
        self.setState({ copied: 'Copied!' })
        setTimeout(() => {
          self.setState({ copied: '' })
        }, 1000)
      }}>
      <span style={{ cursor: 'pointer' }}>{this.props.text} <span className={'copy-text' + (this.state.copied != '' ? ' active' : ' inactive')}>{this.state.copied}</span></span>
    </CopyToClipboard>)
  }
}
var DeploymentsIndex = withRouter(class DeploymentsIndexComponent extends Component {
  state = {
    configurations: [],
    copied: '',
  }

  componentDidMount() {
    this.loadconfigurations()
  }

  loadconfigurations() {
    var self = this
    Api.get('/configurations')
      .then(responseJSON => {
        self.setState({ configurations: responseJSON })
      })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }

  render() {
    return (
      <Fragment>

        <Container fluid>

          <Row>
            <Col md="12">
              <Card className="main-card mb-3">
                <CardHeader>
                  ExpanDrive configurations

                </CardHeader>
                <Table responsive hover striped borderless className="align-middle mb-0">
                  <thead>
                    <tr style={{ height: '1.5rem' }}>
                      <th style={{ width: '30px' }}></th>
                      <th style={{ width: '300px' }}>Name</th>
                      <th style={{ width: '300px' }}>License</th>
                      <th style={{ width: '30px' }}>Active users</th>
                      <th style={{ width: '30px' }}></th>

                    </tr>
                  </thead>
                  <tbody>
                    {this.state.configurations.map(entry => {
                      return (<tr key={entry.id} style={{ height: '3rem', fontSize: '1rem' }} >
                        <td><FontAwesomeIcon className="ml-2 opacity-5" icon={faHdd} /></td>
                        <td ><Link to={`${this.props.match.url}/${entry.id}`}>{entry.name}</Link></td>
                        <td>
                          <CopyableSpan text={licenseKeyWithDashes(entry.key)} />
                        </td>
                        <td>
                          {entry.users?.length ? entry.users.length : ''}
                        </td>
                        <td>

                          <DeleteConfirmModal name={entry.name} id={entry.id} loadconfigurations={this.loadconfigurations.bind(this)} />

                        </td>
                      </tr>)
                    })}

                  </tbody>
                </Table>

                <CardFooter className="text-center d-block p-3">
                  <Link to={`${this.props.match.url}/new`}>
                    <Button color="primary" className="btn-pill btn-shadow btn-wide fsize-1" size="lg">
                      <span className="mr-2 opacity-7">

                      </span>
                      <span className="mr-1">
                        New configuration
                      </span>
                    </Button>
                  </Link>

                </CardFooter>

              </Card>
              <a href="https://docs.expandrive.com/teams/configurations#what-are-configurations">Help - What are configurations?</a>
            </Col>
          </Row>

        </Container>
      </Fragment>
    )
  }
})

export class DeploymentsPage extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (

      <Fragment>
        <Switch>
          <Route path={`${this.props.match.url}/new`} component={DeploymentsNew} />
          <Route path={`${this.props.match.url}/:id/drives/:driveId`} component={DeploymentsEditDrive} />
          <Route path={`${this.props.match.url}/:id`} component={DeploymentsEdit} />
          <Route path={`${this.props.match.url}`} component={DeploymentsIndex} />
        </Switch>
      </Fragment>

    )
  }
}

