import React, { Fragment, Component } from 'react'

import { Col, Row } from 'reactstrap'
import { withRouter } from 'react-router'

import bg1 from 'Images/originals/city'
import expandriveLogo from 'Images/logo-inverse'

class AppSignedIn extends Component {
  render() {
    const queryParams = new URLSearchParams(location.search)
    const token = (queryParams.get('key') === 'true')  
    return (
      <Fragment>
        <div className="h-100" >
          <Row className="h-100 no-gutters" >
            <Col lg="4" className="d-none d-lg-block" >
              <div className="slider-light slick-slider" >

                <div
                  className="h-100 d-flex justify-content-center align-items-center bg-plum-plate" >
                  <div className="slide-img-bg"
                    style={{
                      backgroundImage: 'url(' + bg1 + ')',
                    }
                    } />
                  <div className="slider-content" >
                    <h3>ExpanDrive for Teams</h3>
                    <p>
                      Manage your ExpanDrive deployment for your team
                    </p>
                  </div>
                </div>

              </div>
            </Col>
            <Col lg="8" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center" >
              <Col lg="9" md="10" sm="12" className="mx-auto app-login-box" >
                <input type="hidden" id="jwt_token" value={{token}} />
                <div style={{height: '23px', width: '170px', backgroundImage: 'url(' + expandriveLogo + ')'}} />
                <div className="hr-label"><span className="hr-label__text">Sign in successfull!</span></div>
              </Col>
            </Col>
          </Row>
        </div>
      </Fragment>
    )
  }
}

export default withRouter(AppSignedIn)
