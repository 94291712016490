import {
  UPDATE_CURRENT_ACCOUNT,
} from 'actions'

const currentAccount = (state = {
  name: 'ExpanDrive',
}, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_ACCOUNT:
      return action.account
    default:
      return state
  }
}

export default currentAccount
