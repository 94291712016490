import { Route } from 'react-router-dom'
import React, { Component } from 'react'
import { withRouter, Switch } from 'react-router'
import { connect } from 'react-redux'

import { updateCurrentUser, updateCurrentAccount } from 'actions'

import { DeploymentsPage } from 'Pages/Deployments'

import Login from 'Pages/UserPages/Login'
import { SettingsPage } from 'Pages/Settings'
import BillingPage from 'Pages/Billing'
import AppHeader from 'Layout/AppHeader'
import AppSidebar from 'Layout/AppSidebar'
import AppFooter from 'Layout/AppFooter'
import { UsersPage } from 'Pages/Users'
import { DevicesPage } from 'Pages/Devices'
import Api from 'utils/api'
import { clearSessionData, hasSessionData } from 'utils/session'
import { appSignedInRoutePath, passwordResetRoutePath, registerRoutePath, resetPasswordConfirmationRoutePath, signInRoutePath } from 'config/routePaths'
import AppSignedIn from 'Pages/UserPages/AppSignedIn'

class GoogleAnalytics extends React.Component {
  componentDidUpdate(prevProps) {
    const gtag = window.gtag

    if (prevProps.location.pathname === this.props.location.pathname) {
      // don't log identical link clicks (nav links likely)
      return
    }

    if (this.props.history.action === 'PUSH' &&
      typeof (gtag) === 'function') {
      gtag('config', 'UA-847542-4', {
        'page_title': document.title,
        'page_location': this.props.location.href,
        'page_path': this.props.location.pathname + this.props.location.search,
      })
    }
  }

  render() {
    return null
  }
}

const isLoginRegisterPage = pathname =>
  [signInRoutePath, registerRoutePath, passwordResetRoutePath, resetPasswordConfirmationRoutePath].includes(pathname)

class AppMain extends Component {
  componentDidMount() {
    var self = this

    Api.get('/users/me')
      .then(responseJSON => {
        this.props.updateCurrentUser(responseJSON)
      })
      .catch(() => {
        if (!isLoginRegisterPage(self.props.location.pathname)) {
          self.props.history.push(signInRoutePath)
        }
      })

    Api.get('/account')
      .then(responseJSON => {
        this.props.updateCurrentAccount(responseJSON)
      })
      .catch(() => {
        if (!isLoginRegisterPage(self.props.location.pathname)) {
          self.props.history.push(signInRoutePath)
        }
      })
  }

  render() {
    if (isLoginRegisterPage(this.props.location.pathname)) {
      if (hasSessionData()) {
        clearSessionData()
      }
    }

    if (this.props.location.pathname === signInRoutePath) {
      return <Route path={signInRoutePath} component={Login} />
    }
    if (this.props.location.pathname === appSignedInRoutePath) {
      return <Route path={appSignedInRoutePath} component={AppSignedIn} />
    }

    if (this.props.location.pathname === registerRoutePath) {
      return <Route path={registerRoutePath} component={Login} />
    }

    if (this.props.location.pathname === passwordResetRoutePath) {
      return <Route path={passwordResetRoutePath} component={Login} />
    }

    if (this.props.location.pathname === resetPasswordConfirmationRoutePath) {
      return <Route path={resetPasswordConfirmationRoutePath} component={Login} />
    }

    if (!this.props.currentUser?.id || !this.props.currentAccount?.id) {
      return null
    }

    return (
      <div className="app-container app-theme-white body-tabs-shadow fixed-header fixed-sidebar ">
        <AppHeader />
        <div className="app-main app-theme-white ">
          <AppSidebar />
          <div className="app-main__outer">
            <div className="app-main__inner">
              <Switch>
                <Route path='/configurations' component={DeploymentsPage} />
                <Route path='/devices' component={DevicesPage} />
                <Route path='/billing' component={BillingPage} />
                <Route path='/users' component={UsersPage} />
                {/* <Route path='/groups' component={GroupsPage} /> */}
                <Route path='/settings' component={SettingsPage} />
              </Switch>
              <GoogleAnalytics location={this.props.location} history={this.props.history} />

            </div>
          </div>
          <AppFooter />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentAccount: state['currentAccount'],
  currentUser: state['currentUser'],
})

const mapDispatchToProps = dispatch => ({
  updateCurrentAccount: account => dispatch(updateCurrentAccount(account)),
  updateCurrentUser: user => dispatch(updateCurrentUser(user)),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AppMain),
)
