import { TOGGLE_SIDEBAR } from 'actions'

const sidebar = (state = {
  open: false,
}, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { open: !state.open }
    default:
      return state
  }
}

export default sidebar
