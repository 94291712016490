import {
  UPDATE_CURRENT_USER,
} from 'actions'

const currentUser = (state = {
  name: '',
  email: '',
  admin: false,
}, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return action.user
    default:
      return state
  }
}

export default currentUser
