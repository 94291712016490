import React, { Fragment } from 'react'
import cx from 'classnames'

import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import HeaderLogo from 'Layout/AppLogo'

import UserBox from './Components/UserBox'

class Header extends React.Component {
  render() {
    return (
      <Fragment>
        <CSSTransitionGroup
          component="div"
          className={cx('app-header header-shadow')}
          transitionName="HeaderAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}>

          <HeaderLogo toggleMobileSidebar={this.props.toggleMobileSidebar} />

          <div className={cx(
            'app-header__content app-header',
          )}>

            <div className="app-header-right">
              <a href="https://docs.expandrive.com/teams">Documentation</a>
              <UserBox />
            </div>
          </div>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

export default Header