import { createStore, combineReducers } from 'redux'

import currentAccount from 'reducers/current_account'
import currentUser from 'reducers/current_user'
import users from 'reducers/users'
import devices from 'reducers/devices'
import sidebar from 'reducers/sidebar'

export default function configureStore() {
  return createStore(
    combineReducers({
      currentAccount,
      currentUser,
      sidebar,
      users,
      devices,
    }),
    {},
  )
}