import React, { Fragment } from 'react'

import { StripeProvider } from 'react-stripe-elements'
import { Elements } from 'react-stripe-elements'
import { injectStripe } from 'react-stripe-elements'
import { CardElement } from 'react-stripe-elements'
import { connect } from 'react-redux'

import moment from 'moment'

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap'
import { updateCurrentAccount } from 'actions'
import Api from 'utils/api'

class DoSubscriptionModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error: null,
      modal: false,
      cardName: '',
      submitting: false,
      paymentData: '',

      billingPeriod: 'yearly',
      billingRate: this.props.annualRate,
      cardReady: false,
      buttonDisabled: true,
    }

    this.toggle = this.toggle.bind(this)
  }

  handleSubscribe = ev => {
    ev.preventDefault()

    this.setState({ submitting: true })

    // this.savePaymentData({ id: "abc" });
    // // var self = this
    this.props.stripe.createToken({ name: 'Strongspace' }).then(({ token }) => {
      this.savePaymentData(token)
    })
  }

  savePaymentData(token) {
    var self = this

    var payload = {}

    payload['token'] = token['id']
    payload['period'] = this.state.billingPeriod
    payload['rate'] = this.state.billingRate
    payload['card_name'] = this.state.cardName
    payload['plan_name'] = this.props.planName

    Api.patch('/account', payload)
      .then(responseJSON =>  {
        self.setState({ paymentData: JSON.stringify(token) })
        self.props.updateCurrentAccount(responseJSON)
        self.setState({ modal: false, submitting: false })
      })
      .catch(err => {
        this.setState({ error: err.toString(), submitting: false })
        console.dir(err) // eslint-disable-line no-console
      })
  }

  setBillingPeriod(period) {
    this.setState({
      billingPeriod: period,
      billingRate: period == 'yearly' ? this.props.annualRate : this.props.monthlyRate,
    })
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      cardName: this.props.name,
    })
  }

  handleChange = (e, field) => {
    var newState = {}
    newState[field] = e.target.value
    // console.dir(this.state)
    this.setState(newState)
  }

  handleCardChange() {
    // console.log('handle')
    // console.dir(this.state)
    this.setState({ cardReady: true })
  }

  buttonDisabled() {
    return this.state.submitting || this.state.cardReady != true || this.state.cardName == '' || this.state.cardName == undefined
  }

  render() {
    var self = this
    return (
      <span className='d-inline-block mb-2 mr-2 '>
        <Button className='btn-pill btn-shadow btn-wide fsize-1' size='lg' color='success' onClick={this.toggle}>
          Subscribe
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Subscribe</ModalHeader>
          <ModalBody className='billing-page '>
            <div className='plan-selector billing-modal'>
              <label className='plan-option selected'>
                <div className='radio'>
                  <input
                    name='plan'
                    type='radio'
                    onChange={() => this.setBillingPeriod('yearly')}
                    checked={this.state.billingPeriod == 'yearly'}
                  />
                </div>
                <div className='description'>
                  <h3>Billed Annually</h3>
                  <p className='price'>
                    <span className='prefix'>$</span>
                    <span className='whole'>{this.props.annualRate[0]}</span>
                    <span className='suffix'>{this.props.annualRate.slice(1)}</span>
                  </p>
                  <p className='price-description'>per user per month</p>
                </div>
              </label>
              <label className='plan-option'>
                <div className='radio'>
                  <input
                    name='plan'
                    type='radio'
                    onChange={() => this.setBillingPeriod('monthly')}
                    checked={this.state.billingPeriod == 'monthly'}
                  />
                </div>
                <div className='description'>
                  <h3>Billed Monthly</h3>
                  <p className='price'>
                    <span className='prefix'>$</span>
                    <span className='whole'>{this.props.monthlyRate[0]}</span>
                    <span className='suffix'>{this.props.monthlyRate.slice(1)}</span>
                  </p>
                  <p className='price-description'>per device per month</p>
                </div>
              </label>
            </div>

            <Form>
              <FormGroup>
                <Label for='teamName'>Credit Card</Label>

                <label className='form-control'>
                  <div>
                    <CardElement
                      onChange={el => self.handleCardChange(el)}
                      onReady={el => el.focus()}
                      style={{ base: { fontSize: '18px' } }}
                    />
                  </div>
                </label>
              </FormGroup>
              <FormGroup>
                <Label for='teamName'>Name on Card</Label>
                <Input
                  value={this.state.cardName}
                  onChange={e => this.handleChange(e, 'cardName')}
                  type='text'
                  placeholder=''
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter style={{ flexDirection: 'column', gap: '10px', justifyContent: 'center' }}>
            <Button
              color='success'
              className='btn-pill btn-wide fsize-1 text-center'
              size='lg'
              onClick={this.handleSubscribe}
              disabled={this.buttonDisabled()}
            >
              {!this.state.submitting ? 'Subscribe' : 'Processing...'}
            </Button>
            {' '}

            {this.state.error && !this.state.submitting && (
              <div className="text-danger">
                {this.state.error}
              </div>
            )}
          </ModalFooter>
        </Modal>
      </span>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  currentAccount: state.currentAccount,
})

const mapDispatchToProps = dispatch => {
  return {
    updateCurrentAccount: user => {
      dispatch(updateCurrentAccount(user))
    },
  }
}

var InjectedSubscriptionModal = injectStripe(DoSubscriptionModal)

var ConnectedModal = connect(
  mapStateToProps,
  mapDispatchToProps)(InjectedSubscriptionModal)

export class BillingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { stripe: null, devices: [], users: [] }
  }

  handleChange = (e, field) => {
    var newState = {}
    newState[field] = e.target.value
    this.setState(newState)
  }

  switchPlan(plan) {
    var payload = { plan_name: plan }

    Api.patch('/account', payload)
      .then(responseJSON => {
        // self.setState({ paymentData: JSON.stringify(token) });
        this.props.updateCurrentAccount(responseJSON)
      })
  }

  fetchDeviceCount() {
    var self = this

    Api.get('/devices')
      .then(responseJSON => {
        self.setState({ devices: responseJSON })
      })
  }

  fetchUserCount() {
    var self = this

    Api.get('/users')
      .then(responseJSON => {
        self.setState({ users: responseJSON })
      })
  }
  componentDidMount() {
    this.fetchDeviceCount()
    this.fetchUserCount()
    const script = document.createElement('script')

    script.src = 'https://js.stripe.com/v3/'
    script.async = true
    script.id = 'stripe-js'

    document.body.appendChild(script)
    var stripeKey = 'pk_test_kHfzEIbsbQk8voqJP7bTkzPZ'
    if (window.location.hostname.indexOf('.expandrive.com') > 0) {
      stripeKey = 'pk_live_ZFS48dCl2O9Z1mzndyiHJzAa'
    }

    if (window['Stripe']) {
      this.setState({ stripe: window['Stripe'](stripeKey) })
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.setState({ stripe: window['Stripe'](stripeKey) })
      })
    }
  }

  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <form action='/charge' method='post' id='payment-form' >
            <div className='form-group'>
              {this.props.currentAccount.period == 'trial' && (

                <Fragment><h4>
                  Your account is currently in <span style={{ color: 'green' }}>trial</span>
                </h4>
                <p>
                    You have <span className='badge badge-pill badge-info'>{-moment().diff(this.props.currentAccount.expiration_date, 'days')} days</span> remaining in your free trial.
                    Subscribe now.
                </p>
                </Fragment>
              )}
              {this.props.currentAccount.period != 'trial' && (
                <Fragment><h4>
                  Your subscription is <span style={{ color: 'green' }}>active</span>
                </h4>
                <p>
                    You are on the <span style={{ fontWeight: 600 }} >{this.props.currentAccount.plan_name} Plan</span>, billed {this.props.currentAccount.period} on the {moment(this.props.currentAccount.expiration_date).format('MMMM Do')}{this.props.currentAccount.period == 'yearly ' ? moment(this.props.currentAccount.expiration_date).format(' of MMMM') : ''}.
                    You have  <span className='badge badge-pill badge-info'>{-moment().diff(this.props.currentAccount.expiration_date, 'days')} days</span> until you are billed.
                </p>
                </Fragment>
              )}
            </div>

            <Container fluid={true} className="billing-page">
              <Row>
                <Col md='4'>
                  <Card className='main-card mb-2'>
                    {this.props.currentAccount.plan_name == 'Teams' && (
                      <div className="checkmark-badge">
                        <svg className="corner-badge" preserveAspectRatio="none" viewBox="0 0 1 1"><path
                          d="M1,0H0V1Z"></path>
                        </svg>
                        <svg className="checkmark" viewBox="0 0 20 18" width="20" height="18" fillRule="evenodd">
                          <path d="M0.43 11.77C-0.13 11.21-0.15 10.43 0.39 9.89 0.9 9.34 1.63 9.36 2.32 9.84 3.02 10.32 6.27 12.59 6.59 12.81 6.92 13.04 7.21 12.98 7.42 12.73 7.63 12.47 17.54 0.53 17.54 0.53 17.98-0.05 18.85-0.18 19.45 0.27 20.05 0.71 20.19 1.55 19.72 2.14L8.29 17.46C8.04 17.76 7.61 18 7.2 18 6.84 18 6.51 17.87 6.26 17.63 6.26 17.63 0.99 12.33 0.43 11.77Z"></path></svg></div>
                    )}
                    <CardBody className={this.props.currentAccount.plan_name == 'Teams' ? 'active' : ''}>

                      <div
                        className='text-center'
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ height: '220px' }}>
                          <CardTitle>
                            <h3>Teams</h3>
                          </CardTitle>
                          <p className='price'>
                            <span className='prefix'>$</span>
                            <span className='whole'>3</span>
                            <span className='suffix'>.99</span>
                          </p>
                          <p className='price-description'>
                            <span>per device per month</span>
                            <br />
                            <span>billed annually</span>
                          </p>
                          {this.props.currentAccount.period == 'trial' && (
                            <Form>
                              <ConnectedModal planName={'Teams'} name={this.state['name']} annualRate='3.99' monthlyRate='4.99' />
                            </Form>
                          )}
                          {!!this.props.currentAccount.subscription_id && this.props.currentAccount.plan_name == 'Business' && (
                            <Button outline color="success" className='btn-pill btn-wide fsize-1' size='lg' onClick={() => this.switchPlan('Teams')}>
                              Switch to Teams
                            </Button>)}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col md='4'>
                  <Card className='main-card mb-2'>
                    {this.props.currentAccount.plan_name == 'Business' && (
                      <div className="checkmark-badge">
                        <svg className="corner-badge" preserveAspectRatio="none" viewBox="0 0 1 1"><path
                          d="M1,0H0V1Z"></path>
                        </svg>
                        <svg className="checkmark" viewBox="0 0 20 18" width="20" height="18" fillRule="evenodd">
                          <path d="M0.43 11.77C-0.13 11.21-0.15 10.43 0.39 9.89 0.9 9.34 1.63 9.36 2.32 9.84 3.02 10.32 6.27 12.59 6.59 12.81 6.92 13.04 7.21 12.98 7.42 12.73 7.63 12.47 17.54 0.53 17.54 0.53 17.98-0.05 18.85-0.18 19.45 0.27 20.05 0.71 20.19 1.55 19.72 2.14L8.29 17.46C8.04 17.76 7.61 18 7.2 18 6.84 18 6.51 17.87 6.26 17.63 6.26 17.63 0.99 12.33 0.43 11.77Z"></path></svg></div>
                    )}
                    <CardBody className={this.props.currentAccount.plan_name == 'Business' ? 'active' : ''}>
                      <div
                        className='text-center'
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <div style={{ height: '220px' }}>
                          <CardTitle>
                            <h3>Business</h3>
                          </CardTitle>
                          <p className='price'>
                            <span className='prefix'>$</span>
                            <span className='whole'>5</span>
                            <span className='suffix'>.99</span>
                          </p>
                          <p className='price-description'>
                            <span>per user per month</span>
                            <br />
                            <span>billed annually</span>
                          </p>
                          {this.props.currentAccount.period == 'trial' && (
                            <Form>
                              <ConnectedModal planName={'Business'} name={this.state['name']} annualRate='5.99' monthlyRate='6.99' />
                            </Form>
                          )}
                          {!!this.props.currentAccount.subscription_id && this.props.currentAccount.plan_name == 'Teams' && (
                            <Button outline color="success" className='btn-pill btn-wide fsize-1' size='lg' onClick={() => this.switchPlan('Business')}>
                              Switch to Business
                            </Button>)}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <p>
                    Your account has {this.state.devices.length} active devices.
               and {this.state.users.length} named users.
                </p>
              </Row>
            </Container>

            <br />
          </form>
        </Elements>
      </StripeProvider>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BillingPage)
