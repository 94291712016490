import React, { Component } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'

import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import HeaderLogo from 'Layout/AppLogo'

import { withRouter, Link } from 'react-router-dom'
import MetisMenu from 'react-metismenu'
import { toggleSidebar } from 'actions'

export var JLink = withRouter(class CustomLink extends React.Component {
  constructor(props) {
    super(props)

    this.onClick = this.onClick.bind(this)
  }

  onClick() {
    var to = this.props.to
    if (to == '#') {
      to = '/configurations'
    }

    this.props.history.push(to)
  }
  render() {
    var to = this.props.to
    if (to == '#') {
      to = '/configurations'
    }

    var linkActive = false
    // this.props.location.pathname.split("/")[2] == this.props.to.split("/")[2] || 

    if (this.props.location.pathname == this.props.to) {
      linkActive = true
    }

    if (this.props.location.pathname.split('/')[1] != undefined && (this.props.location.pathname.split('/')[1] == this.props.to.split('/')[1])) {
      linkActive = true
    }

    if (this.props.to == '#') {
      return (<div className={'has-active-child metismenu-link header'}>{this.props.children}</div>)
    }
    if (this.props.hasSubMenu) {
      return (
        <Link id={this.props.id} className={`metismenu-link has-active-child ${linkActive ? this.props.classNameActive : ''}`} onClick={this.onClick.bind(this)} to={to}>{this.props.children} </Link>

      )
    }
    return (
      <Link id={this.props.id} className={`metismenu-link has-active-child ${linkActive ? this.props.classNameActive : ''}`} onClick={this.onClick.bind(this)} to={to}>{this.props.children}</Link>

    )
  }
})

class SidebarNav extends Component {
  state = {
    accountName: 'Expan3Drive',
  }

  buildMenu() {
    if (this.props.currentAccount == undefined)
      return []
    return [
      {
        icon: 'pe-7s-diamond',
        label: this.props.currentAccount.name,
        active: true,
        content: [
          {
            label: 'Configurations',
            to: '/configurations',
          },
          {
            label: 'Users',
            to: '/users',
          },
          {
            label: 'Devices',
            to: '/devices',
          },

          {
            label: 'Billing',
            to: '/billing',
          },
          {
            label: 'Account Settings',
            to: '/settings',
          },

        ],
      },
    ]
  }
  render() {
    return (
      <MetisMenu LinkComponent={JLink} classNameContainer={'visible'} iconNameStateHidden={'2'} classNameStateIcon={'7 d'} iconNameStateVisible={'3'} content={this.buildMenu()} className="vertical-nav-menu" iconNamePrefix="" />
    )
  }
}

class AppSidebar extends Component {
  render() {
    return (
      <div className={this.props.sidebar.open ? 'sidebar-mobile-open' : ''}>
        <div className="sidebar-mobile-overlay " onClick={() => this.props.toggleSidebar()} />
        <CSSTransitionGroup
          component="div"
          className={cx('app-sidebar')}
          transitionName="SidebarAnimation"
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}>

          <HeaderLogo />

          <div className="app-sidebar__inner">
            <SidebarNav currentAccount={this.props.currentAccount} />
          </div>

        </CSSTransitionGroup>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  currentUser: state.currentUser,
  currentAccount: state.currentAccount,
  sidebar: state.sidebar,
})

const mapDispatchToProps = dispatch => {
  return {
    toggleSidebar: () => {
      dispatch(toggleSidebar())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar)