
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'

import ExpanDriveProApp from './Layout/AppMain'
import configureStore from './config/configureStore'
import { clearSessionData, loadSessionData } from './utils/session'

const store = configureStore()

loadSessionData()

const SignOut = () => {
  clearSessionData()
  return <Redirect to="/users/sign_in" />
}

const routes = (
  <GoogleOAuthProvider clientId={process.env.GOOGLE_CLIENT_ID}>
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={() => {return(<Redirect to="/configurations" />)}} />
          <Route exact component={SignOut} path="/users/sign_out" />
          <Route component={ExpanDriveProApp} path="/*" />
        </Switch>
      </BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>
)

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(routes, document.getElementById('root'))
})
