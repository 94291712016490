import React, { Fragment } from 'react'
import { connect } from 'react-redux'

import Hamburger from 'react-hamburgers'

import UserBox from 'Layout/AppHeader/Components/UserBox'
import { toggleSidebar } from 'actions'

class AppMobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      mobile: false,
      activeSecondaryMenuMobile: false,
    }
  }

  state = {
    openLeft: false,
    openRight: false,
    relativeWidth: false,
    width: 280,
    noTouchOpen: false,
    noTouchClose: false,
  }

  render() {
    return (
      <Fragment>
        <div className="app-header__mobile-menu">
          <div onClick={this.toggleMobileSidebar}>
            <Hamburger
              active={this.state.activeMobile}
              type="elastic"
              onClick={() => { this.props.toggleSidebar(); this.setState({ activeMobile: !this.state.activeMobile }) }}
            />
          </div>
        </div>
        <div className="app-header__menu">
          <UserBox />
        </div>
      </Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleSidebar: () => {
      dispatch(toggleSidebar())
    },
  }
}

export default connect(null, mapDispatchToProps)(AppMobileMenu)
